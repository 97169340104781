import React from "react";
import Section from "./Section";

function TestimonialsSection(props) {
  const testimonials = [
    {
      body: "I didn't know the reason my pieces took so long to sell on Facebook Marketplace was because of poor staging!! FurniSnapp changed my flipping journey",
      name: "Stephanie Wardell",
      image: "/reviewimages/review1.png",
    },
    {
      body: "Staging used to take me hours of moving heavy furniture and setting up photo shoots. I'm glad that's over now!",
      name: "Kelly D Green",
      image: "/reviewimages/review2.png",
    },
    {
      body: "The staging the AI makes is really so beautiful! I've noticed that my pieces are selling much faster now",
      name: "Danielle Brooks",
      image: "/reviewimages/review3.png",
    },
    {
      body: "I heard of many flippers using this tool but I was hesitant to try it at first. I'm glad I eventually did!",
      name: "Alicia Reaves",
      image: "/reviewimages/review4.png",
    },
    {
      body: "I was shocked at how much of a difference staging made when flipping furniture. FurniSnapp removed so many headaches for me.",
      name: "Kendra Perkins",
      image: "/reviewimages/review5.png",
    },
    {
      body: "I had tried SO MANY staging 'tricks and tips' because I was so frustrated when my pieces used to not sell. I wish I heard about FurniSnapp sooner.",
      name: "Lakshmi James",
      image: "/reviewimages/review6.png",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <section className="py-12">
        <div className="container mx-auto px-4">
          <div className="mb-8">
            <p className="text-muted-foreground uppercase tracking-wide">Reviews</p>
            <h2 className="text-5xl font-bold leading-tight">What other flippers are saying</h2>
          </div>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <div className="p-6 border rounded-lg shadow-sm" key={index}>
                <p className="text-lg mb-4">{testimonial.body}</p>
                <div className="flex items-center mt-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div className="ml-3">
                    <p className="font-bold">{testimonial.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Section>
  );
}

export default TestimonialsSection;