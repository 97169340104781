import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";
import { getStripePriceId } from "./prices";

let stripe;
let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  apiVersion: "2020-08-27",
});

stripePromise.then(
  (stripeInstance) => {
    console.log("Stripe initialized successfully");
    stripe = stripeInstance;
  },
  (error) => {
    console.error("Failed to initialize Stripe:", error);
  }
);

export async function redirectToCheckout(planId) {
  if (!stripe) {
    console.error("Stripe hasn't loaded yet. Current state:", stripe);
    throw new Error("Stripe hasn't loaded yet. Please try again in a moment.");
  }

  try {
    console.log("Getting Stripe price ID for plan:", planId);
    const priceId = getStripePriceId(planId);
    console.log("Stripe price ID:", priceId);

    if (!priceId) {
      throw new Error(`No Stripe price ID found for plan: ${planId}`);
    }

    console.log("Creating checkout session with price ID:", priceId);
    const session = await apiRequest("stripe-create-checkout-session", "POST", {
      priceId: priceId,
      successUrl: `${window.location.origin}/dashboard?paid=true`,
      cancelUrl: `${window.location.origin}/pricing`,
    });

    console.log("Checkout session created:", session);

    if (session.error) {
      throw new Error(session.error.message || 'An error occurred while creating the checkout session');
    }

    console.log("Redirecting to Stripe checkout");
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      throw new Error(result.error.message);
    }
  } catch (error) {
    console.error("Error in redirectToCheckout:", error);
    throw new Error(`Failed to redirect to checkout: ${error.message}`);
  }
}

export async function redirectToBilling() {
  try {
    console.log("Starting redirectToBilling");
    // Create a billing session
    const response = await apiRequest("stripe-create-billing-session", "POST", {
      returnUrl: `${window.location.origin}/settings/general`,
    });
    console.log("Received session response:", response);

    if (response.status === "error") {
      throw new Error(response.message || "Error creating billing session");
    }

    const session = response.data;

    if (!session || !session.url) {
      console.error("Invalid session data:", session);
      throw new Error("Invalid session data received from server");
    }

    // Redirect to the Stripe Billing Portal URL
    window.location.href = session.url;
  } catch (error) {
    console.error("Error creating billing session:", error);
    throw error;
  }
}