import React from "react";

export default function Mplace5() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center min-h-screen p-4 bg-white">
      <div className="w-full md:w-1/2 p-4">
        <img
          src="/mplace5.1.jpeg"
          alt="Interior design"
          className="w-full h-full object-cover rounded-lg"
          width="500"
          height="500"
          style={{ aspectRatio: "500/500", objectFit: "cover" }}
        />
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h4 className="text-sm font-semibold text-gray-500 uppercase mb-2">Online Exclusive</h4>
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Unique designs</h2>
        <p className="text-gray-700 mb-4">
          Our designer series will feature a range of furniture pieces, from sophisticated living room sofas to
          functional and stylish office furniture. Each piece has been carefully designed and crafted to ensure maximum
          comfort, durability, and aesthetics.
        </p>
        <p className="text-gray-700">
          Our exclusive collaboration with 45 designers represents a significant milestone in our commitment to
          providing our customers with the best in furniture design.
        </p>
      </div>
    </div>
  )
}