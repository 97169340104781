import React from "react";
import Meta from "./../components/Meta";
import Mplace1 from "./../components/mplace1";
import Mplace2 from "./../components/mplace2";
import Mplace3 from "./../components/mplace3";
import Mplace4 from "./../components/mplace4";
import Mplace5 from "./../components/mplace5";
import Mplace6 from "./../components/mplace6";

function MplacePage(props) {
  return (
    <>
      <Meta title="Marketplace" />
      <div className="space-y-16 mt-8">
        <Mplace1 />
        <Mplace2 />
        <Mplace3 />
        <Mplace4 />
        <Mplace5 />
        <Mplace6 />
      </div>
    </>
  );
}

export default MplacePage;