import React from "react";
import Meta from "./../components/Meta";
import ImageEditingSection from "./../components/ImageEditingSection";
import { requireAuth } from "./../util/auth";

function DashboardPage() {
  return (
    <>
      <Meta title="Dashboard" />
      <div className="container mx-auto px-4 py-8">
        <ImageEditingSection />
      </div>
    </>
  );
}

export default requireAuth(DashboardPage);