import React from "react";

export default function Logo() {
  return (
    <div className="flex flex-col items-center space-y-4">
      <p className="text-sm text-muted-foreground">USED BY FLIPPERS AND DESIGNERS FROM THE WORLD'S LEADING BRANDS</p>
      <div className="flex space-x-8 items-center">
        <img
          src="/quartz2.svg"
          alt="Quartz"
          className="h-10"
          width="100"
          height="40"
          style={{ aspectRatio: "100/40", objectFit: "contain" }}
        />
        <img
          src="/nyt2.svg"
          alt="The New York Times"
          className="h-10"
          width="150"
          height="40"
          style={{ aspectRatio: "150/40", objectFit: "contain" }}
        />
        <img
          src="/forbes2.svg"
          alt="Forbes"
          className="h-10"
          width="100"
          height="40"
          style={{ aspectRatio: "100/40", objectFit: "contain" }}
        />
        <img
          src="/entrepreneur2.svg"
          alt="Entrepreneur"
          className="h-10"
          width="150"
          height="40"
          style={{ aspectRatio: "150/40", objectFit: "contain" }}
        />
      </div>
    </div>
  )
}