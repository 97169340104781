import React, { useState } from "react";

function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  )
}

function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  )
}

export default function BeforeAfterSection() {
  const beforeAfterItems = [
    {
      before: "no serious buyers for a month",
      after: "sold 2 hours after using autostaging",
      beforeImage: "/beforeafter/before1.jpg",
      afterImage: "/beforeafter/after1.png"
    },
    {
      before: "only received lowball offers",
      after: "sold for $180 above asking price",
      beforeImage: "/beforeafter/before2.jpg",
      afterImage: "/beforeafter/after2.jpg"
    },
    {
      before: "listed for a month with no offers",
      after: "sold within 3 hours",
      beforeImage: "/beforeafter/before3.jpg",
      afterImage: "/beforeafter/after3.jpeg"
    }
  ];

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-sm font-semibold text-gray-500">BEFORE & AFTER</h2>
        <h1 className="mt-2 text-3xl font-bold">
          Flippers <span className="text-blue-600">sell 42% faster</span> using FurniSnapp
        </h1>
      </div>
      <div className="container mx-auto mt-8 grid grid-cols-1 gap-8 md:grid-cols-3">
        {beforeAfterItems.map((item, index) => (
          <BeforeAfterBox key={index} item={item} />
        ))}
      </div>
    </section>
  )
}

function BeforeAfterBox({ item }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [item.beforeImage, item.afterImage];

  const toggleImage = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'right') {
        return (prevIndex + 1) % images.length;
      } else {
        return (prevIndex - 1 + images.length) % images.length;
      }
    });
  };

  return (
    <div className="p-4 border rounded-lg shadow-sm">
      <div className="relative overflow-hidden" style={{ aspectRatio: "200/300" }}>
        <div 
          className="flex transition-transform duration-300 ease-in-out"
          style={{ 
            transform: `translateX(-${currentIndex * 33.333}%)`,
            width: '300%'
          }}
        >
          {[...images, images[0]].map((image, index) => (
            <div key={index} className="w-1/3 h-full flex items-center justify-center">
              <img
                src={image}
                alt={index % 2 === 0 ? "Before" : "After"}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </div>
        <div className="absolute inset-0 flex items-center justify-between px-4">
          <button 
            className="p-2 bg-white rounded-full shadow-md" 
            onClick={() => toggleImage('left')}
          >
            <ChevronLeftIcon className="w-4 h-4 text-blue-600" />
          </button>
          <button 
            className="p-2 bg-white rounded-full shadow-md" 
            onClick={() => toggleImage('right')}
          >
            <ChevronRightIcon className="w-4 h-4 text-blue-600" />
          </button>
        </div>
      </div>
      <p className="mt-4 text-sm text-gray-700">
        <strong>Before:</strong> {item.before}
        <br />
        <strong>After:</strong> {item.after}
      </p>
    </div>
  );
}