import React from "react";
import { useAuth } from "../util/auth";

export default function Mplace4() {
  const auth = useAuth();

  return (
    <div className="bg-[#111111] text-white p-8">
      <div className="max-w-5xl mx-auto">
        <header className="mb-8">
          <p className="text-sm text-muted-foreground uppercase">Gallery</p>
          <h1 className="text-4xl font-bold">Our furniture, in real life</h1>
          <div className="flex justify-between mt-4">
            <p className="text-muted-foreground max-w-md">
              From stylish and modern sofas to classic dining tables, our gallery is a visual treat for anyone looking
              for inspiration and ideas for their home decor.
            </p>
            <p className="text-muted-foreground max-w-md">
              See below for a collection of photographs from real homes that highlight the quality, design, and
              craftsmanship of our furniture pieces.
            </p>
          </div>
        </header>
        <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <img
              key={num}
              src={`/mplace4.${num}.jpeg`}
              alt={`Gallery ${num}`}
              className="w-full h-auto rounded-lg"
              width="300"
              height="300"
              style={{ aspectRatio: "300/300", objectFit: "cover" }}
            />
          ))}
        </main>
        {auth.user && (
          <div className="mt-8">
            <h2 className="text-2xl font-bold">Protected Content</h2>
            <p>This content is only visible to authenticated users.</p>
          </div>
        )}
      </div>
    </div>
  );
}