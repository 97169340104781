import React from "react";
import Button from "./Button";

export default function Mplace1() {
  return (
    <div className="flex flex-col items-center justify-center py-12 bg-white">
      <div className="flex flex-col items-start justify-between w-full max-w-7xl p-6 bg-white rounded-lg lg:flex-row">
        <div className="flex flex-col space-y-3 lg:w-1/2">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 lg:text-4xl">
            The furniture marketplace built for <span className="text-blue-600">flippers</span>, by{" "}
            <span className="text-blue-600">flippers</span>
          </h1>
          <p className="text-base text-gray-600 lg:text-lg">
            FurniSnapp is the one-stop marketplace for flippers to buy and sell redesigned furniture
          </p>
          <div className="flex space-x-4 mt-3">
            <Button size="md">Shop now</Button>
            <Button variant="outline" size="md">Learn more</Button>
          </div>
        </div>
        <div className="mt-6 lg:mt-0 lg:ml-6 lg:w-1/2">
          <img
            src="/mplace1.1.jpeg"
            alt="Stylish furniture"
            className="rounded-lg self-center w-full h-auto"
            width="2400"
            height="1600"
            style={{ aspectRatio: "2400/1600", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
}