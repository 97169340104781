import React from "react";
import { Link } from "./../util/router";
import Section from "./Section";

function Footer(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={`${props.sticky ? "mt-auto" : ""} pt-0 pb-0`}
    >
      <footer className="flex justify-between items-center px-4 text-muted-foreground" style={{ padding: 0 }}>
        <div className="flex flex-col space-y-1">
          <div className="flex space-x-4">
            <Link to="/" className="font-semibold">
              Home
            </Link>
            <Link to="/marketplace" className="font-semibold">
              Marketplace
            </Link>
          </div>
          <div className="flex space-x-4">
            <span>© FurniSnapp</span>
            <span>|</span>
            <Link to="/legal/terms-of-service">
              Terms of Use
            </Link>
            <span>|</span>
            <Link to="/legal/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;