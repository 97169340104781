import React from "react";
import Meta from "./../components/Meta";
import MarketplaceLp from "./../components/MarketplaceLp";
import { requireAuth } from "./../util/auth";

function MarketplacePage(props) {
  return (
    <>
      <Meta title="Marketplace" />
      <MarketplaceLp
        title="Dashboard"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(MarketplacePage);
