// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  base: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  flipper: process.env.REACT_APP_STRIPE_PRICE_PRO,
  premium: process.env.REACT_APP_STRIPE_PRICE_BUSINESS,
};

console.log("Stripe Price IDs:", stripePriceIds);

// Get Stripe priceId
export function getStripePriceId(planId) {
  console.log("Getting price ID for plan:", planId);
  const priceId = stripePriceIds[planId];
  console.log("Price ID found:", priceId);
  if (!priceId) {
    console.error(`No Stripe price ID found for plan: ${planId}`);
  }
  return priceId;
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}