import React from "react";

export default function Mplace6() {
  const reviews = [
    {
      content: "I absolutely loved the sofa that I ordered. Customer service was amazing.",
      name: "Jeff Conner",
      location: "New York, NY",
      initials: "JC",
      image: "/mplace6.1.jpeg"
    },
    {
      content: "Shipment arrived in the color and appearance I expected and in perfect condition. The chairs look exactly as they are shown on the website. I love them.",
      name: "Emma Pérez",
      location: "Los Angeles, CA",
      initials: "EP",
      image: "/mplace6.2.jpeg"
    },
    {
      content: "The furniture is so well made and looks incredible. Customer service is so easy to get a hold of and helpful.",
      name: "Raj Mishra",
      location: "Austin, TX",
      initials: "RM",
      image: "/mplace6.3.jpeg"
    },
    {
      content: "Perfect experience. Right on time, and the overall quality (design, details, etc) was over my expectations.",
      name: "John Freidman",
      location: "Philadelphia, PA",
      initials: "JF",
      image: "/mplace6.4.jpeg"
    },
    {
      content: "Absolutely amazing customer service and delivery team. 10/10!",
      name: "Francis Gaddi",
      location: "San Francisco, CA",
      initials: "FG",
      image: "/mplace6.5.jpeg"
    },
    {
      content: "I've received so many compliments for my Soren Bergman chair! Really impressed by the quality of the furniture as well as the experience.",
      name: "Aya Williams",
      location: "Chicago, IL",
      initials: "AW",
      image: "/mplace6.6.jpeg"
    }
  ];

  return (
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h2 className="text-sm font-semibold text-gray-500">REVIEWS</h2>
      <h1 className="mt-2 text-4xl font-bold">What people are saying</h1>
      <div className="grid gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-3">
        {reviews.map((review, index) => (
          <div key={index} className="p-6 bg-white rounded-lg shadow-md">
            <p className="text-lg">{review.content}</p>
            <div className="flex items-center mt-4">
              <div className="w-10 h-10 rounded-full overflow-hidden">
                <img 
                  src={review.image} 
                  alt={review.name} 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4">
                <p className="font-semibold">{review.name}</p>
                <p className="text-sm text-gray-500">{review.location}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}