import React, { useState, useEffect } from "react";
import Button from "./Button";
import axios from "axios";
import { useAuth } from "./../util/auth";
import { createImageEdit } from "./../util/db";
import { useUser, updateUser } from "./../util/db";
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from "./../util/router";

export default function ImageEditingSection() {
  const auth = useAuth();
  const router = useRouter();
  const { data: user } = useUser(auth.user?.uid);
  const [selectedOption, setSelectedOption] = useState("simple");
  const [inputImage, setInputImage] = useState(null);
  const [outputImage, setOutputImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState(0);
  const [customPrompt, setCustomPrompt] = useState("");
  const [showFeedbackIcons, setShowFeedbackIcons] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (user) {
      setCredits(user.creditsRemaining);
    }
  }, [user]);

  const getBackgroundPrompt = (style) => {
    switch (style) {
      case "simple":
        return "On a dark wooden floor with a cream colored wall in the background";
      case "detailed":
        return "A professionally staged background for wooden dressers and cabinets. The background should be minimal and include perfect decor, lighting, and a single plants. Don't make the background too cluttered.";
      case "custom":
        return customPrompt || "Custom background prompt";
      default:
        return "a nice living room";
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setInputImage(file);
  };

  const handleGenerateImage = async () => {
    if (!inputImage) {
      alert("Please upload an image first");
      return;
    }

    if (credits <= 0) {
      router.push("/auth/signup");
      return;
    }

    setIsLoading(true);

    const backgroundPrompt = getBackgroundPrompt(selectedOption);

    const formData = new FormData();
    formData.append("imageFile", inputImage);
    formData.append("background.prompt", backgroundPrompt);
    formData.append("background.negativePrompt", "no furniture");
    formData.append("referenceBox", "originalImage");

    try {
      const response = await axios.post('https://image-api.photoroom.com/v2/edit', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": process.env.REACT_APP_PHOTOROOM_API_KEY,
        },
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);
      setOutputImage(imageUrl);
      setShowFeedbackIcons(true);

      await saveImageEditToSupabase(imageUrl, backgroundPrompt);

      const newCredits = credits - 1;
      await updateUser(auth.user.uid, { creditsRemaining: newCredits });
      setCredits(newCredits);
    } catch (error) {
      console.error("Error processing image:", error);
      alert("An error occurred while processing the image");
    } finally {
      setIsLoading(false);
    }
  };

  const saveImageEditToSupabase = async (outputImageUrl, backgroundPrompt) => {
    try {
      const inputImageUrl = URL.createObjectURL(inputImage);
      const imageEditData = {
        user_id: auth.user.uid,
        input_image_url: inputImageUrl,
        output_image_url: outputImageUrl,
        background_style: backgroundPrompt,
      };

      await createImageEdit(imageEditData);
      console.log("Image edit saved to Supabase");
    } catch (error) {
      console.error("Error saving image edit to Supabase:", error);
    }
  };

  const handleDownload = () => {
    if (outputImage) {
      const link = document.createElement('a');
      link.href = outputImage;
      link.download = 'staged_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleShare = () => {
    if (outputImage) {
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(outputImage)}`;
      window.open(shareUrl, '_blank', 'width=600,height=400');
    }
  };

  const handleFeedback = (isPositive) => {
    console.log(isPositive ? "Positive feedback" : "Negative feedback");
    setShowFeedbackIcons(false);
    if (!isPositive) {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSeeTips = () => {
    window.open("https://docs.google.com/document/d/1c-O1vpfWt1Ogcrh1gARyOMrNX-vpUnUbgEiw-rkKGBI/edit", "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 bg-gray-100">
      <div className="text-center mb-8">
        <p className="text-sm text-gray-500">HOW IT WORKS</p>
        <h1 className="text-3xl font-bold">
          3 easy steps to <span className="text-blue-600">instantly</span> stage your furniture
        </h1>
      </div>
      <div className="flex justify-around gap-x-20 mb-8">
        {[1, 2, 3].map((step, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="flex items-center justify-center w-12 h-12 border-2 border-black rounded-full">
              <span className="text-lg font-bold">{step}</span>
            </div>
            <p className="mt-2 text-center">
              {index === 0 ? "Upload your image" : index === 1 ? "Press \"Generate Now\"" : "Download your photo"}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-around items-start gap-x-8 bg-gray-900 p-8 rounded-lg w-full max-w-7xl mx-auto">
        {/* Input Image Area */}
        <div className="flex flex-col w-full md:w-2/5">
          <div className="flex flex-col items-center justify-center w-full p-8 bg-white border border-gray-300 rounded-lg h-[400px] mb-4">
            {inputImage ? (
              <img
                src={URL.createObjectURL(inputImage)}
                alt="Input"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <label className="cursor-pointer text-gray-500">
                Click to upload an image
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
              </label>
            )}
          </div>
          <div className="mt-4">
            <p className="text-white text-center mb-2">Select a background style:</p>
            <div className="flex justify-center space-x-2">
              {["simple", "detailed", "custom"].map((option) => (
                <Button
                  key={option}
                  variant="outline"
                  className={`px-3 py-2 ${selectedOption === option ? "bg-blue-600 text-white" : "text-gray-300"}`}
                  onClick={() => setSelectedOption(option)}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Button>
              ))}
            </div>
            {selectedOption === "custom" && (
              <div className="mt-4">
                <input
                  type="text"
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  placeholder="Enter custom background prompt"
                  className="w-full px-3 py-2 text-black rounded"
                />
              </div>
            )}
          </div>
        </div>

        {/* Generate Button and Credits */}
        <div className="flex flex-col items-center justify-center w-full md:w-1/5 h-[400px]">
          <div className="flex flex-col items-center justify-center space-y-4">
            <Button
              className={`px-6 py-3 text-lg font-semibold ${credits > 0 ? "bg-green-600" : "bg-blue-600"} text-white`}
              onClick={credits > 0 ? handleGenerateImage : () => router.push("/auth/signup")}
              disabled={isLoading || (!inputImage && credits > 0)}
            >
              {isLoading ? "Processing..." : credits > 0 ? "Generate Now" : "Get Free Credits"}
            </Button>
            <p className="text-white">
              Credits Available: <span className="font-bold">{credits}</span>
            </p>
          </div>
        </div>

        {/* Output Image Area */}
        <div className="flex flex-col w-full md:w-2/5">
          <div className="flex flex-col items-center justify-center w-full p-8 bg-white border border-gray-300 rounded-lg h-[400px] mb-4">
            {outputImage ? (
              <img
                src={outputImage}
                alt="Output"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <p className="text-gray-500">Output Image here</p>
            )}
          </div>
          {showFeedbackIcons && (
            <div className="flex justify-center space-x-4 mb-4">
              <button
                onClick={() => handleFeedback(true)}
                className="p-2 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
              >
                <HandThumbUpIcon className="w-6 h-6 text-white" />
              </button>
              <button
                onClick={() => handleFeedback(false)}
                className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
              >
                <HandThumbDownIcon className="w-6 h-6 text-white" />
              </button>
            </div>
          )}
          <div className="flex justify-center space-x-4 mt-4">
            <Button
              variant="outline"
              className="px-4 py-2 bg-white text-black hover:bg-gray-100"
              disabled={!outputImage}
              onClick={handleDownload}
            >
              Download
            </Button>
            <Button
              variant="outline"
              className="bg-blue-600 text-white px-4 py-2 hover:bg-blue-700"
              disabled={!outputImage}
              onClick={handleShare}
            >
              Share on Facebook
            </Button>
          </div>
        </div>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-bold">Didn't like the image?</h2>
              <button onClick={handleClosePopup} className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">
              Check out these 3 tips to start getting better images and boost sales:
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                onClick={handleClosePopup}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Close
              </Button>
              <Button
                onClick={handleSeeTips}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                See Tips Now
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}