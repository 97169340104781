import React from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";

function Meta(props) {
  const router = useRouter();

  // Default meta values
  const defaultMeta = {
    title: "FurniSnapp | AI-Powered Furniture Staging",
    description: "Transform your furniture flipping business with FurniSnapp. Use AI to instantly generate professionally staged photos, sell faster, and boost profits.",
    image: "/images/social-image.jpg", // Make sure this image exists in your public folder
    type: "website",
  };

  // Merge default and custom meta
  const meta = { ...defaultMeta, ...props };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:url" content={`https://furnisnapp.com${router.pathname}`} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={`https://furnisnapp.com${meta.image}`} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://furnisnapp.com${router.pathname}`} />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={`https://furnisnapp.com${meta.image}`} />

      {/* Additional SEO-friendly meta tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="furniture staging, AI staging, furniture flipping, home decor, interior design" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={`https://furnisnapp.com${router.pathname}`} />
    </Helmet>
  );
}

export default Meta;