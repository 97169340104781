import React from "react";

export default function Mplace2() {
  return (
    <div className="flex items-center justify-center py-16 bg-black">
      <blockquote className="text-center text-white max-w-3xl px-4">
        <p className="text-2xl italic">
          "Your home should tell the story of who you are, and be a collection of what you love."
        </p>
        <footer className="mt-4 text-lg">
          - Nate Berkus
        </footer>
      </blockquote>
    </div>
  )
}