import React, { useState, useEffect } from "react";
import Section from "./Section";
import Button from "./Button";
import { Link } from "./../util/router";
import ImageEditingSection from "./ImageEditingSection";
import BeforeAfterSection from "./BeforeAfterSection";

export default function HeroSection2() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const heroImages = [
    "/heroimage1.jpeg",
    "/heroimage2.jpeg",
    "/heroimage3.jpeg"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [heroImages.length]);

  return (
    <Section
      size="lg"
      bgColor="bg-white"
      bgImage=""
      bgImageOpacity={1}
      textColor=""
      noPadding
    >
      <div className="pt-8">
        <div className="flex flex-col md:flex-row justify-center items-center gap-[40px] pl-5 pr-3 max-w-[1240px] mx-auto">
          <div className="flex flex-col justify-center max-w-[420px] w-full">
            <div className="flex flex-col gap-5">
              <h1 className="text-[44px] font-semibold leading-tight text-[#0E1E2F]">
                Finish staging in <span className="text-[#1165EF]">2 minutes</span>, not 2 hours
              </h1>
              <p className="text-[19px] text-[#757F87] leading-[1.4]">
                Use AI to instantly generate professionally staged photos for your flipping project
              </p>
              <div className="mt-[15px]">
                <Button
                  component={Link}
                  to="/pricing"
                  size="xl"
                  variant="primary"
                  className="px-[15px] py-[15px] text-[19px] font-semibold bg-[#1165EF] text-white rounded-[5px] w-full max-w-[320px] h-[55px]"
                >
                  Get started
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full max-w-[650px] h-[500px] flex items-center justify-center overflow-hidden">
            <div 
              className="flex transition-transform duration-500 ease-in-out"
              style={{ 
                transform: `translateX(-${currentImageIndex * 100}%)`,
                width: `${heroImages.length * 100}%`
              }}
            >
              {heroImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Staged furniture ${index + 1}`}
                  className="object-contain w-full h-full rounded-md flex-shrink-0"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <ImageEditingSection />
      </div>
      <BeforeAfterSection />
    </Section>
  );
}