import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";

function HomePage(props) {
  return (
    <>
      <Meta 
        title="FurniSnapp | AI-Powered Furniture Staging for Flippers"
        description="Transform your furniture flipping business with FurniSnapp. Use AI to instantly generate professionally staged photos, sell faster, and boost profits. Try it now!"
      />
      <HeroSection2
        title="Flip Furniture Faster with AI-Powered Staging"
        subtitle="Transform your flips into professional, staged masterpieces in seconds. Boost sales and save time with FurniSnapp's cutting-edge AI technology."
        strapline="AI-Powered Furniture Staging"
        size="lg"
        bgColor="bg-blue-900"
        bgImage="/hero-background.jpg"
        bgImageOpacity={0.7}
        textColor="text-white"
      />
      <TestimonialsSection
        title="What Flippers Are Saying"
        subtitle="Join thousands of satisfied flippers who've transformed their business with FurniSnapp"
        strapline="Success Stories"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Start Flipping Smarter <span className="text-blue-600">Today</span>!
          </>
        }
        subtitle="Join thousands of flippers who are selling faster and earning more with FurniSnapp. Start your free trial and see the difference AI-powered staging can make for your business."
        strapline="Ready to Transform Your Flips?"
        size="md"
        bgColor="bg-gray-100"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default HomePage;