import React from "react";

export default function Mplace3() {
  return (
    <div className="flex flex-col items-center p-8 space-y-8">
      <div className="text-center space-y-2">
        <p className="text-sm font-semibold text-muted-foreground">FEATURED COLLECTION</p>
        <h1 className="text-4xl font-bold">The designers</h1>
        <p className="text-muted-foreground">
          We've partnered with the world's most acclaimed designers to create 45 exclusive furnitures
        </p>
      </div>
      <div className="grid gap-8 md:grid-cols-3">
        <div className="flex flex-col items-center space-y-4">
          <img
            src="/mplace3.1.jpeg"
            alt="Aria Rossi"
            className="rounded-lg"
            width="300"
            height="300"
            style={{ aspectRatio: "300/300", objectFit: "cover" }}
          />
          <div className="text-center">
            <h2 className="text-lg font-semibold">Aria Rossi</h2>
            <p className="text-muted-foreground">
              Italian designer inspired by modern and minimalist shapes with the use of playful colors
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <img
            src="/mplace3.2.jpeg"
            alt="Lucca Moretti"
            className="rounded-lg"
            width="300"
            height="300"
            style={{ aspectRatio: "300/300", objectFit: "cover" }}
          />
          <div className="text-center">
            <h2 className="text-lg font-semibold">Lucca Moretti</h2>
            <p className="text-muted-foreground">
              Prolific designer based in Milan who specializes in marble furniture
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <img
            src="/mplace3.3.jpeg"
            alt="Soren Bergman"
            className="rounded-lg"
            width="300"
            height="300"
            style={{ aspectRatio: "300/300", objectFit: "cover" }}
          />
          <div className="text-center">
            <h2 className="text-lg font-semibold">Soren Bergman</h2>
            <p className="text-muted-foreground">
              LA-based multidisciplinary designer known for his pop art and hand painted furniture
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}